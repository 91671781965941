import cx from 'classnames'

interface Props {
  className?: string
}

export default (props:Props) => {
  return (
    <svg 
      className={cx('Arrow', props.className)} 
      width='11' 
      height='11' 
      viewBox='0 0 11 11' 
      xmlns='http://www.w3.org/2000/svg'
      aria-label='An arrow icon, indicating a link.'
    >
      <path stroke='currentColor' fill='none' d='M0.517578 1.08984H10.0293V10.6943'/>
      <path stroke='currentColor' d='M0.890625 10.4141L9.79492 1.50977' />
    </svg>
  )
}