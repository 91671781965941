import cx from 'classnames'
import AnimatedArcs from '../../components/animated-arcs'
import { ArcVariant, getArcVariantFromString } from '../../components/animated-arcs/AnimatedArcs'
import { BackgroundStyle } from '../../components/background-style'
import BlockViewportAnimation from '../../components/block-viewport-animation'

interface Props {
  title: string
  subtitle: string
  alignment: 'Left' | 'Center'
  backgroundStyle: BackgroundStyle
  arcVariant: '1' | '2' | '3'
}

export default (props:Props) => {
  const titleHTML:string = props.title.replace('{', '<span class="HeroBlock--titleAlt h2">').replace('}', '</span>')
  const isPurple:boolean = props.backgroundStyle.toLowerCase().indexOf('purple') >= 0
  const arcVariant:ArcVariant = getArcVariantFromString(props.arcVariant)
  return (
    <div className={cx('HeroBlock', isPurple && 'HeroBlock--purple')}>
      <AnimatedArcs 
        className='HeroBlock--arcs' 
        backgroundStyle={props.backgroundStyle}
        variant={arcVariant}
      >
        <BlockViewportAnimation className='HeroBlock--inner'>
          <div className={cx('container', props.alignment !== 'Left' && 'text--center')}>
            <h1 className='HeroBlock--title h3' dangerouslySetInnerHTML={{ __html:titleHTML}} />
            
            {props.subtitle && (
              <h2 className='HeroBlock--subtitle h6'>{props.subtitle}</h2>
            )}
          </div>
        </BlockViewportAnimation>
      </AnimatedArcs>
    </div>
  )
}