import cx from 'classnames'
import { useState } from 'react'
import BackgroundStyleComponent, { BackgroundStyle } from '../../components/background-style'
import CountUp from '../../components/count-up'
import ViewportObserverOneTime from '../../components/viewport-observer-onetime'

interface StatisticsBlockItem {
  preNumberLabel: number
  statisticNumber: number
  numberLabel: string|null
  description: string
}

interface Props {
  backgroundStyle: BackgroundStyle
  items: Array<StatisticsBlockItem>
}

export default (props:Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const onObservedChanged = (visible:boolean) => {
    if (isVisible || !visible) {
      return
    }

    setIsVisible(true)
  }

  return (
    <ViewportObserverOneTime 
      className='StatisticsBlock' 
      classNameObserved='StatisticsBlock--visible'
      onObservedChanged={onObservedChanged}
    >
      <BackgroundStyleComponent background={props.backgroundStyle} className={cx('StatisticsBlock--inner')}>
        <div className={cx('container')}>
          <div className={cx('gw')}>
            {props.items && props.items.map( (item:StatisticsBlockItem) => {
              return (
                <div key={item.numberLabel} className={cx('StatisticsBlock--item g desk-one-third')}>
                  <div className={cx('StatisticsBlock--textContent')}>
                    <h3 className='StatisticsBlock--title'>
                      {item.preNumberLabel && (
                        item.preNumberLabel
                      )}
                      {isVisible && (
                        <CountUp>{item.statisticNumber}</CountUp>
                      )}
                      {item.numberLabel}
                    </h3>
                    <p className='StatisticsBlock--description'>{item.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </BackgroundStyleComponent>
    </ViewportObserverOneTime>
  )
}