import cx from 'classnames'
import { Link, NavigationLink } from '../../content-provider'
import Anchor from '../anchor'
import Logo from '../logo'
import WYSIWYG from '../wysiwyg'
import GetStartedModal from "../get-started-modal/GetStartedModal";
import {useState} from "react";

interface Props {
  copyright: string|null
  address: string|null
  emailAddress: string|null
  footerHeading: string|null
  footerCTA: NavigationLink|null
  footerLeftColumnLinks: Array<NavigationLink>
  footerMiddleColumnLinks: Array<NavigationLink>
  footerRightColumnLinks: Array<NavigationLink>
  socialMediaLinks: Array<NavigationLink>
}

export default (props:Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applyNowLink, setApplyNowLink] = useState<NavigationLink | null>(null);

  return (
    <div className={cx('Footer')}>
      <div className='Footer--inner'>
        <div className='container'>
          <div className='gw'>
            <div className='g'>
              <div className='Footer--getStarted text--center'>
                <h1 className='Footer--title'>{props.footerHeading}</h1>
                {props.footerCTA && (
                  <button
                    className={cx('btn', 'btn--white')}
                    onClick={() => {
                      setApplyNowLink(props.footerCTA);
                      setIsModalOpen(true);
                    }}
                  >
                    {props.footerCTA.text}
                  </button>
                )}
              </div>
            </div>
            
            <div className='g hidden-below-desk'>
              <div className='Footer--divider' />
            </div>

            <div className='g hidden-below-desk desk-one-sixth'>
              <Logo className='Footer--logo' />
            </div>
            
            <div className='g push--one-quarter one-half push--desk-zero'>
              {renderInfo(props)}
            </div>

            {props.footerLeftColumnLinks && props.footerLeftColumnLinks.length > 0 && (
              <div className='g push--one-quarter one-half push--desk-zero desk-one-sixth'>
                {renderLinks(props.footerLeftColumnLinks)}
              </div>
            )}

            {props.footerMiddleColumnLinks && props.footerMiddleColumnLinks.length > 0 && (
              <div className='g push--one-quarter one-half push--desk-zero desk-one-sixth'>
                <ul className={cx('Footer--linkList v-list')}>
                  {props.footerMiddleColumnLinks.map( (item:NavigationLink) => {
                    if (item.text === 'Get Started') {
                      return (
                        <li className={cx('Footer--linkListItem')} key={item.url}>
                          <Anchor link={item} className='Footer--link text--uppercase a--white' forceArrow={true} onClick={(event) => {
                            event.preventDefault();
                            setApplyNowLink(item);
                            setIsModalOpen(true);
                          }}/>
                        </li>
                      )
                    }

                    return (
                      <li className={cx('Footer--linkListItem')} key={item.url}>
                        <Anchor link={item} className='Footer--link text--uppercase a--white' forceArrow={true} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}

            {props.footerRightColumnLinks && props.footerRightColumnLinks.length > 0 && (
              <div className='g push--one-quarter one-half push--desk-zero desk-one-sixth'>
                {renderLinks(props.footerRightColumnLinks)}
              </div>
            )}

            {props.socialMediaLinks && props.socialMediaLinks.length > 0 && (
              <div className='g push--one-quarter one-half push--desk-zero desk-one-sixth'>
                {renderLinks(props.socialMediaLinks)}
              </div>
            )}

            <GetStartedModal open={isModalOpen} onClose={() => setIsModalOpen(false)} applyNowLink={applyNowLink} />
          </div>
        </div>
      </div>
    </div>
  )
}

const renderInfo = (props:Props) => {
  return (
    <div className='Footer--info'>
      {props.copyright && (
        <WYSIWYG html={props.copyright} className='Footer--copyright text--uppercase' />
      )}

      {props.address && (
        <WYSIWYG html={props.address} className='Footer--address text--uppercase Footer--copyrightLast' />
      )}

      {props.emailAddress && (
        <a className='Footer--email a--white text--uppercase' href={`mailto:${props.emailAddress}`}>{props.emailAddress}</a>
      )}
    </div>
  )
}

const renderLinks = (links:Array<NavigationLink>) => {
  if (!links) {
    return null
  }
  
  return (
    <ul className={cx('Footer--linkList v-list')}>
      {links.map( (item:Link) => {
        return (
          <li className={cx('Footer--linkListItem')} key={item.url}>
            <Anchor link={item} className='Footer--link text--uppercase a--white' forceArrow={true} />
          </li>
        )
      })}
    </ul>
  )
}