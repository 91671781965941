import cx from 'classnames'
import React from 'react'
import Anchor from '../../components/anchor'
import BackgroundStyleComponent, { BackgroundStyle } from '../../components/background-style'
import BlockViewportAnimation from '../../components/block-viewport-animation'
import { Image, Link } from '../../content-provider'

interface Props {
  video: Image
  image: Image
  imageAlignment: 'Left'|'Right'
  title: string
  description: string|null
  caption: string|null
  link: Link|null
  backgroundStyle: BackgroundStyle
}

export default (props:Props) => {
  return (
    <div className='HalfHalfBlock'>
      <BackgroundStyleComponent background={props.backgroundStyle} className={cx('HalfHalfBlock--inner')}>
        <BlockViewportAnimation className='container'>
          <div className={cx('HalfHalfBlock--grid gw', props.imageAlignment === 'Right' && 'gw--rev')}>
            <div className={cx('HalfHalfBlock--imageHalf g desk-one-half')}>
              {renderMedia(props)}
            </div>
            <div className={cx('HalfHalfBlock--textHalf g desk-one-half')}>
              <h5 className='HalfHalfBlock--title'>{props.title}</h5>

              {props.description && (
                <p className='HalfHalfBlock--description'>{props.description}</p>
              )}

              {props.caption && (
                <p className='HalfHalfBlock--caption typography--bodyItalics'>
                  {props.caption}
                </p>
              )}
              
              {props.link && (
                <Anchor
                  link={props.link}
                  forceArrow={true}
                  className={cx(
                    'HalfHalfBlock--anchor',
                    'a--btn',
                    (props.backgroundStyle === BackgroundStyle.Purple) && 'a--white'
                  )}
                />
              )}
            </div>
          </div>
        </BlockViewportAnimation>
      </BackgroundStyleComponent>
    </div>
  )
}

const renderMedia = (props: Props):React.ReactNode => {
  if (props.video) {
    return(
      <video className='HalfHalfBlock--media HalfHalfBlock--mediaVideo' autoPlay muted playsInline disableRemotePlayback poster={props.image.url}>
        <source src={props.video.url} type='video/mp4' />
      </video>
    )
  }
  return  (
    <img className='HalfHalfBlock--media HalfHalfBlock--mediaImage' src={props.image.url} alt={props.image.alt} />
  )
}