import React from 'react'

interface Props {
  container: string
}

export default (props:Props) => {
  return (
    <React.Fragment>
      <noscript>
        <iframe
          src={"https://www.googletagmanager.com/ns.html?id=" + props.container}
          width="0" height="0"
          style={{ display:"none", visibility:"hidden" }}
        ></iframe>
      </noscript>
    </React.Fragment>
  )
}