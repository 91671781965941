import cx from 'classnames'
import React from 'react'
import ViewportObserver from '../viewport-observer/ViewportObserver'

interface Props {
  className?: string
  classNameObserved?: string
  onObservedChanged?: (observed:boolean) => any
  children: React.ReactNode
}

interface State {
  observed:boolean
}

export default class extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props)
    this.state = {
      observed: false
    }
  }

  render = () => {
    return (
      <ViewportObserver 
        className={cx(this.props.className, this.state.observed && this.props.classNameObserved)} // Do not use the `classNameObserved=` prop of ViewportObserver, this will cause bugs since we're overriding the behaviour here.
        onObservedChanged={this.onObservedChanged}
      >
        {this.props.children}
      </ViewportObserver>
    )
  }

  onObservedChanged = (observed:boolean) => {
    if (this.state.observed) { // Only allow it to be set to true. This means it will effectively only be able to be set to true once.
      return
    }

    this.setState({ observed:observed }, () => {
      if (this.props.onObservedChanged) {
        this.props.onObservedChanged(this.state.observed)
      }
    })
  }
}