import cx from 'classnames'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { AnimatedEllipsis, createRecaptchaToken, isValidEmailAddress } from 'lunr-core/browser'
import React from 'react'
import { BackgroundStyle } from '../../components/background-style'

interface Props {
  backgroundStyle: BackgroundStyle
  onSubmit: (values:EarlyAccessRequestInput) => Promise<boolean>
}

interface State {
  failed: boolean
}

export interface EarlyAccessRequestInput {
  emailAddress: string
  recaptcha: string
}

const initialFormValues:EarlyAccessRequestInput = {
  emailAddress: '',
  recaptcha: ''
}

type StringDictionary = { [key:string]:string }

export default class extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props)

    this.state = {
      failed: false
    }
  }
  
  render = () => {
    return (
      <Formik
        initialValues={initialFormValues}
        validate={(values:EarlyAccessRequestInput) => {
          const errors:StringDictionary = {}
          
          if (!values.emailAddress) {
            errors.emailAddress = 'Required'
          }
          else if (!isValidEmailAddress(values.emailAddress)) {
            errors.emailAddress = 'Invalid email address'
          }
          else if (values.emailAddress.length > 255) {
            errors.emailAddress = 'Must be less than 255 characters'
          }

          return errors
        }}
        onSubmit={async (values:EarlyAccessRequestInput, helpers:FormikHelpers<EarlyAccessRequestInput>) => {
          helpers.setSubmitting(true)

          this.setState({ failed:false })

          const recaptcha:string = await createRecaptchaToken(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!, 'request_early_request_access')
          const success:boolean = await this.props.onSubmit({
            ...values,
            recaptcha: recaptcha
          })
          helpers.setSubmitting(false)

          if (success) {
            helpers.resetForm()
          }
          
          this.setState({ failed:!success })

          return false
        }}
      >
        {(helpers:FormikProps<EarlyAccessRequestInput>) => (
          <div className={cx('EarlyAccessRequestForm', helpers.isSubmitting && 'EarlyAccessRequestForm--submitting')}>
            <form onSubmit={helpers.handleSubmit}>
              <div className='gw'>
                <div className='g'>
                  <div className={cx('form-group', helpers.touched.emailAddress && helpers.errors.emailAddress && 'has-error')}>
                    <input
                      type='email'
                      name='emailAddress'
                      aria-label='Email address'
                      className='form-control'
                      placeholder='Enter your email'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.emailAddress}
                      maxLength={255}
                    />
                    {helpers.errors.emailAddress && helpers.touched.emailAddress && (
                      <p className='has-error'>{helpers.errors.emailAddress}</p>
                    )}
                  </div>
                </div>
                
                {Object.keys(helpers.touched).length > 0 && Object.keys(helpers.errors).length > 0 && (
                  <div className='g'>
                    <p className='EarlyAccessRequestForm--errorSummary error'>Please fill out all required fields.</p>
                  </div>
                )}

                {this.state.failed && (
                  <div className='g'>
                    <p className='EarlyAccessRequestForm--errorSummary error'>Failed to submit form! Please try again later.</p>
                  </div>
                )}

                
                <div className='g'>
                  <button className={cx('EarlyAccessRequestForm--submit btn', this.props.backgroundStyle === BackgroundStyle.Purple && 'btn--white')} type='submit' disabled={helpers.isSubmitting}>
                    {helpers.isSubmitting ? <AnimatedEllipsis /> : 'Submit'}
                  </button>
                </div>

                <div className='g EarlyAccessRequestForm--recaptcha-legal'>
                  <small className=''>This site is protected by reCAPTCHA and the Google <br />
                      <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                  </small>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    )
  }
}