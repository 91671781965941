import React, { ReactNode } from 'react'
import BlockFactory from '../../blocks/BlockFactory'
import { Block, Page, SiteSettings } from '../../content-provider'
import Footer from '../footer'
import { GoogleTagManagerBodyScripts } from '../google-tag-manager'
import Header from '../header'
import PageHead from '../page-head'

interface Props {
  /**
   * The retrieved CMS page data. Falsey if no page data was found.
   */
  page?: Page|null|undefined
  
  /**
   * The site settings retrieved from the CMS. Falsey if no site settings were able to be pulled.
   */
  siteSettings?: SiteSettings|null|undefined

  /**
   * Optionally manually pass in children instead of relying on the page data coming from the CMS.
   * Useful for cases where the CMS was unable to provide page information (error pages).
   */
  children?: ReactNode|null|undefined
}

interface State {

}

export default class Layout extends React.Component<Props, State> {
  render = () => {
    return (
      <React.Fragment>
        <PageHead
          page={this.props.page}
          siteSettings={this.props.siteSettings}
        />

        {this.props.siteSettings && (
          <Header
            page={this.props.page}
            navigation={this.props.siteSettings.mainNavigationLinks}
            headerCTA={this.props.siteSettings.headerCTA}
            headerLoginLink={this.props.siteSettings.headerLoginLink}
          />
        )}
        
        {this.props.page && this.props.page.blocks && this.props.page.blocks.map( (block:Block<any>, index:number) => {
          return (
            <BlockFactory key={block.name + '-' + index} block={block} />
          )
        })}

        {this.props.children}

        {/* <pre>{JSON.stringify(this.props.page, null, '\t')}</pre> */}
        {this.props.siteSettings && (
          <Footer
            copyright={this.props.siteSettings.copyright}
            address={this.props.siteSettings.address}
            emailAddress={this.props.siteSettings.emailAddress}
            footerHeading={this.props.siteSettings.footerHeading}
            footerCTA={this.props.siteSettings.footerCTA}
            footerLeftColumnLinks={this.props.siteSettings.footerLeftColumnLinks}
            footerMiddleColumnLinks={this.props.siteSettings.footerMiddleColumnLinks}
            footerRightColumnLinks={this.props.siteSettings.footerRightColumnLinks}
            socialMediaLinks={this.props.siteSettings.socialMediaLinks}
          />
        )}

        <GoogleTagManagerBodyScripts container={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER!} />
      </React.Fragment>
    )
  }
}