import { Fragment, useEffect, useState } from 'react'

interface Props {
  children?: any
  duration?: number
}

const easeOutQuad = (t:number) => t * ( 2 - t )
const frameDuration:number = 1000 / 60

const CountUp = (props:Props) => {
  let countTo:number = 100
  if (props.children) {
    countTo = parseInt(props.children, 10)
  }

	const [count, setCount] = useState<number>(0)

	useEffect( () => {
		let frame:number = 0
    let animationDuration:number = 2000
    let totalFrames:number = 0
    if (props.duration) {
      totalFrames = Math.round(props.duration / frameDuration)
    }
    else {
      totalFrames = Math.round(animationDuration / frameDuration)
    }
		
		const counter = setInterval(() => {
			frame++
			const progress:number = easeOutQuad(frame / totalFrames)
			setCount(countTo * progress)

			if (frame === totalFrames) {
				clearInterval(counter)
			}
		}, frameDuration)
	}, [])

	return (
    <Fragment>
      {Math.floor(count)}
    </Fragment>
  )
}

export default CountUp