import cx from 'classnames'
import BackgroundStyleComponent, { BackgroundStyle } from '../../components/background-style'
import { Image } from '../../content-provider'
import contentfulImageResizer from '../../content-provider/contentful/contentfulImageResizer'
import BlockViewportAnimation from '../../components/block-viewport-animation'

interface Props {
  image: Image
  backgroundStyle: BackgroundStyle
}

export default (props:Props) => {
    return (
      <div className='FullImageBlock'>
        <BackgroundStyleComponent background={props.backgroundStyle}>
          <BlockViewportAnimation className='container'>
            <img src={props.image.url} alt="full image"/>
          </BlockViewportAnimation>
        </BackgroundStyleComponent>
      </div>
    )
  }