import cx from 'classnames'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { createRecaptchaToken, isValidEmailAddress } from 'lunr-core/browser'
import React from 'react'
import { ProcessApplicationFormInput } from '../../../pages/api/process-application-form'

interface Props {
  onSubmit: (values:ProcessApplicationFormInput) => Promise<boolean>
}

interface State {
  failed: boolean
  progressText: string
}

const initialFormValues:ProcessApplicationFormInput = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  companyWebsite: '',
  lastTwelveMonthGMV: '',
  yearToDateGMV: '',
  comments: '',
  recaptcha: ''
}

type StringDictionary = { [key:string]:string }

export default class ApplicationForm extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props)

    this.state = {
      failed: false,
      progressText: '...'
    }
  }
  
  render = () => {
    return (
      <Formik
        initialValues={initialFormValues}
        validate={(values:ProcessApplicationFormInput) => {
          const errors:StringDictionary = {}
          
          if (!values.firstName) {
            errors.firstName = 'Required'
          }
          else if (values.firstName.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (!values.lastName) {
            errors.lastName = 'Required'
          }
          else if (values.lastName.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (!values.email) {
            errors.email = 'Required'
          }
          else if (!isValidEmailAddress(values.email)) {
            errors.email = 'Invalid email address'
          }
          else if (values.email.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (!values.companyName) {
            errors.companyName = 'Required'
          }
          else if (values.companyName.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (!values.companyWebsite) {
            errors.companyWebsite = 'Required'
          }
          else if (values.companyWebsite.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (!values.lastTwelveMonthGMV) {
            errors.lastTwelveMonthGMV = 'Required'
          }
          else if (values.lastTwelveMonthGMV.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (!values.yearToDateGMV) {
            errors.yearToDateGMV = 'Required'
          }
          else if (values.yearToDateGMV.length > 255) {
            errors.firstName = 'Must be less than 255 characters'
          }

          if (values.comments && values.comments.length > 10000) {
            errors.comments = 'Must be less than 10000 characters'
          }

          return errors
        }}
        onSubmit={async (values:ProcessApplicationFormInput, helpers:FormikHelpers<ProcessApplicationFormInput>) => {
          helpers.setSubmitting(true)
          this.setState({ failed:false, progressText:'.'})

          const interval = setInterval(() => {
            let newText:string = this.state.progressText + '.'
            if (newText.length >=4) {
              newText = '.'
            }
            this.setState({ progressText:newText })
          }, 500)

          const recaptcha:string = await createRecaptchaToken(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!, 'application_form')
          const success:boolean = await this.props.onSubmit({
            ...values,
            recaptcha: recaptcha
          })
          helpers.setSubmitting(false)
          clearInterval(interval)
          
          if (success) {
            helpers.resetForm()
          }
          
          this.setState({ failed:!success })

          return false
        }}
      >
        {(helpers:FormikProps<ProcessApplicationFormInput>) => (
          <div className={cx('ApplicationForm', helpers.isSubmitting && 'ApplicationForm--submitting')}>
            <form onSubmit={helpers.handleSubmit}>
              <div className='gw'>
                <div className='g desk-one-half'>
                  <div className={cx('form-group', helpers.touched.firstName && helpers.errors.firstName && 'has-error')}>
                    <input
                      type='text'
                      name='firstName'
                      aria-label='First Name'
                      className='form-control'
                      placeholder='First Name'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.firstName}
                      maxLength={255}
                    />
                    {helpers.errors.firstName && helpers.touched.firstName && (
                      <p className='has-error'>{helpers.errors.firstName}</p>
                    )}
                  </div>
                </div>
              
                <div className='g desk-one-half'>
                  <div className={cx('form-group', helpers.touched.lastName && helpers.errors.lastName && 'has-error')}>
                    <input
                      type='text'
                      name='lastName'
                      aria-label='Last Name'
                      className='form-control'
                      placeholder='Last Name'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.lastName}
                      maxLength={255}
                    />
                    {helpers.errors.lastName && helpers.touched.lastName && (
                      <p className='has-error'>{helpers.errors.lastName}</p>
                    )}
                  </div>
                </div>
              
                <div className='g'>
                  <div className={cx('form-group', helpers.touched.email && helpers.errors.email && 'has-error')}>
                    <input
                      type='email'
                      name='email'
                      aria-label='Email Address'
                      className='form-control'
                      placeholder='Email Address'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.email}
                      maxLength={255}
                    />
                    {helpers.errors.email && helpers.touched.email && (
                      <p className='has-error'>{helpers.errors.email}</p>
                    )}
                  </div>
                </div>

                <div className='g'>
                  <div className={cx('form-group', helpers.touched.companyName && helpers.errors.companyName && 'has-error')}>
                    <input
                      type='text'
                      name='companyName'
                      aria-label='Company Name'
                      className='form-control'
                      placeholder='Company Name'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.companyName}
                      maxLength={255}
                    />
                    {helpers.errors.companyName && helpers.touched.companyName && (
                      <p className='has-error'>{helpers.errors.companyName}</p>
                    )}
                  </div>
                </div>

                <div className='g'>
                  <div className={cx('form-group', helpers.touched.companyWebsite && helpers.errors.companyWebsite && 'has-error')}>
                    <input
                      type='text'
                      name='companyWebsite'
                      aria-label='Company Website'
                      className='form-control'
                      placeholder='Company Website'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.companyWebsite}
                      maxLength={255}
                    />
                    {helpers.errors.companyWebsite && helpers.touched.companyWebsite && (
                      <p className='has-error'>{helpers.errors.companyWebsite}</p>
                    )}
                  </div>
                </div>

                <div className='g'>
                  <div className={cx('form-group', helpers.touched.lastTwelveMonthGMV && helpers.errors.lastTwelveMonthGMV && 'has-error')}>
                    <input
                      type='text'
                      name='lastTwelveMonthGMV'
                      aria-label='Last Twelve Month GMV'
                      className='form-control'
                      placeholder='Last 6 month sales'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.lastTwelveMonthGMV}
                      maxLength={255}
                    />
                    {helpers.errors.lastTwelveMonthGMV && helpers.touched.lastTwelveMonthGMV && (
                      <p className='has-error'>{helpers.errors.lastTwelveMonthGMV}</p>
                    )}
                  </div>
                </div>

                <div className='g'>
                  <div className={cx('form-group', helpers.touched.yearToDateGMV && helpers.errors.yearToDateGMV && 'has-error')}>
                    <input
                      type='text'
                      className='form-control'
                      aria-label='Year-to-date GMV'
                      name='yearToDateGMV'
                      placeholder='Gross Margin'
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.yearToDateGMV}
                      maxLength={255}
                    />
                    {helpers.errors.yearToDateGMV && helpers.touched.yearToDateGMV && (
                      <p className='has-error'>{helpers.errors.yearToDateGMV}</p>
                    )}
                  </div>
                </div>

                <div className='g'>
                  <div className={cx('form-group', helpers.touched.comments && helpers.errors.comments && 'has-error')}>
                    <textarea
                      name='comments'
                      className='form-control'
                      aria-label='Comments'
                      placeholder='Additional Comments'
                      rows={10}
                      onChange={helpers.handleChange}
                      onBlur={helpers.handleBlur}
                      value={helpers.values.comments}
                      maxLength={10000}
                    />
                    {helpers.errors.comments && helpers.touched.comments && (
                      <p className='has-error'>{helpers.errors.comments}</p>
                    )}
                  </div>
                </div>
                
                {Object.keys(helpers.touched).length >=0 && Object.keys(helpers.errors).length > 0 && (
                  <div className='g'>
                    <p className='ApplicationForm--errorSummary error'>Please fill out all required fields.</p>
                  </div>
                )}

                {this.state.failed && (
                  <div className='g'>
                    <p className='ApplicationForm--errorSummary error'>Failed to submit form! Please try again later.</p>
                  </div>
                )}

                <div className='g'>
                  <button className='ApplicationForm--submit btn btn--white' type='submit' disabled={helpers.isSubmitting}>
                    {helpers.isSubmitting ? this.state.progressText : 'Submit'}
                  </button>
                </div>

                <div className='g ApplicationForm--recaptcha-legal'>
                  <small className=''>This site is protected by reCAPTCHA and the Google <br />
                      <a className='a--white' href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                      <a className='a--white' href="https://policies.google.com/terms">Terms of Service</a> apply.
                  </small>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    )
  }
}