import { Maybe } from 'lunr-core/browser'
import BlockViewportAnimation from '../../components/block-viewport-animation'
import { Image, Link } from '../../content-provider'
import Anchor from '../../components/anchor'

interface Props {
  name: string
  title: string
  image: Image
  description: string
  link?: Maybe<Link>
}

export default (props:Props) => {
    return (
      <div className='EmployeeBlock'>
        <BlockViewportAnimation className='container'>
          <div className='landmark'>
            <h1 className='h5'>{props.name}</h1>
            <h2 className='h6 EmployeeBlock--title'>{props.title}</h2>
          </div>
          <div className='gw'>
            <div className='g lap-one-half EmployeeBlock--image'>
              <img src={props.image.url} alt={props.name} />
            </div>
            <div className='g lap-one-half'>
              <div dangerouslySetInnerHTML={{__html:props.description}} />

              {props.link && (
                <div className='island'>
                  <Anchor className='btn' link={props.link} />
                </div>
              )}
            </div>
          </div>
        </BlockViewportAnimation>
      </div>
    )
  }