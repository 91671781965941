import cx from 'classnames'
import Logo from '../../components/logo'
import LogoIcon from '../../components/logo-icon'
import BlockViewportAnimation from '../../components/block-viewport-animation'

interface ProcessBlockItem {
  title: string
  subtitle: string
  description: string
}

interface Props {
  title: string
  items: Array<ProcessBlockItem>
}

export default (props:Props) => {
  return (
    <div className={cx('ProcessBlock')}>
      <div className='container'>
        <BlockViewportAnimation className='ProcessBlock--inner'>
          <h1 className='ProcessBlock--title typography--subhead hidden-below-desk'>{props.title}</h1>

          <div className='ProcessBlock--items'>
            <div className='ProcessBlock--itemsInner'>
              {/* <pre>{JSON.stringify(props, null, '\t')}</pre> */}
              {props.items && props.items.map( (item:ProcessBlockItem, index:number) => {
                return (
                  <div className={cx('ProcessBlock--item', 'ProcessBlock--item' + (index+1))}>
                    <h2 className='ProcessBlock--itemTitle h3'>{item.title}</h2>
                    <h3 className='ProcessBlock--itemSubtitle typography--subheadAlt'>{item.subtitle}</h3>
                    <p className='ProcessBlock--itemDescription marginless'>{item.description}</p>
                  </div>
                )
              })}

              <div className={cx('ProcessBlock--item ProcessBlock--itemLogo')}>
                <LogoIcon className='ProcessBlock--itemLogoIcon' />
              </div>
            </div>
          </div>
        </BlockViewportAnimation>
      </div>
    </div>
  )
}