import cx from 'classnames'
import Link from 'next/link'
import React from 'react'
import { NavigationLink, Page } from '../../content-provider'
import getFirstBlockBackgroundStyle from '../../next/getFirstBlockBackgroundStyle'
import Anchor from '../anchor'
import { BackgroundStyle } from '../background-style'
import Logo from '../logo'
import HamburgerButton from './HamburgerButton'
import NavigationLinkList from './NavigationLinkList'
import GetStartedModal from "../get-started-modal/GetStartedModal";

interface Props {
  page?: Page|null|undefined
  navigation: Array<NavigationLink>
  headerCTA: NavigationLink|null
  headerLoginLink: NavigationLink|null
}

interface State {
  isOpen: boolean
  scrolled: boolean,
  isModalOpen: boolean
}

export default class Header extends React.Component<Props, State> {

  constructor(props:Props) {
    super(props)
    this.state = {
      isOpen: false,
      scrolled: false,
      isModalOpen: false
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.onWindowScroll)
    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onWindowScroll)
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowScroll = () => {
    this.setState({ scrolled:(window.scrollY > 50)})
  }

  onWindowResize = () => {
    this.setState({ isOpen:false })
  }

  toggleOpen = () => {
    this.setState({ isOpen:!this.state.isOpen })
  }

  onLinkSelected = () => {
    this.setState({ isOpen:false })
  }

  render = () => {
    const style:BackgroundStyle = getFirstBlockBackgroundStyle(this.props.page)
    const isPurple:boolean = (style === BackgroundStyle.Purple || style == BackgroundStyle.PurpleTop)

    return (
      <div 
        className={cx(
          'Header', 
          isPurple && 'Header--purple',
          this.state.scrolled && 'Header--scrolled', 
          this.state.isOpen && 'Header--isOpen'
        )}
      >
        <div className='container'>
          <header className={cx('Header--inner')}>
            <Link 
              href='/' 
              className='Header--logo a--noAnimation'
              onClick={this.onLinkSelected}
            >
              <Logo className='Header--logoImage' />
            </Link>

            <HamburgerButton
              className={cx('Header--hamburger hidden-desk-and-up')}
              onClick={this.toggleOpen}
              isOpen={this.state.isOpen}
            />

            <nav className='Header--nav'>
              <NavigationLinkList
                className='Header--navList'
                classNameItem='Header--navListItem'
                classNameAnchor={cx('Header--navListAnchor typography--descriptor', isPurple && 'a--white')}
                items={this.props.navigation}
                onClick={this.onLinkSelected}
              >
                {this.props.headerCTA && (
                  <button
                    className={cx('btn Header--navListCTA', isPurple && 'btn--white')}
                    onClick={() => this.setState({
                      isModalOpen: true
                    })}
                  >
                    {this.props.headerCTA.text}
                  </button>
                )}

                <GetStartedModal open={this.state.isModalOpen} onClose={() => this.setState({
                  isModalOpen: false
                })} applyNowLink={this.props.headerCTA} />

                {this.props.headerLoginLink && (
                  <li key={this.props.headerLoginLink.url} className={cx('Header--navListItem')}>
                    <Anchor
                      className={cx('Anchor Header--navListAnchor typography--descriptor', isPurple && 'a--white')}
                      link={this.props.headerLoginLink}
                      ignoreArrow={true}
                    />
                  </li>
                )}
              </NavigationLinkList>
            </nav>
          </header>
        </div>
      </div>
    )
  }
}