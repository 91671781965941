import React from 'react'

interface Props {
  container: string
}

export default (props:Props) => {
  return (
    <React.Fragment>
      <script async src={"https://www.googletagmanager.com/gtag/js?id=" + props.container}></script>
      <script dangerouslySetInnerHTML={{ __html:`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${props.container}');`}}></script>
    </React.Fragment>
  )
}