import React from 'react'

interface Props {
  
}

export default (props:Props) => {
  return (
    <React.Fragment>
      <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
      <link rel="icon" type="image/gif" href="/images/favicons/favicon.gif" />
      <link rel="manifest" href="/images/favicons/site.webmanifest" />
      <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="shortcut icon" href="/images/favicons/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
    </React.Fragment>
  )
}