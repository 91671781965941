import { RecaptchaScript } from 'lunr-core/browser'
import Head from 'next/head'
import React from 'react'
import { Page, SiteSettings } from '../../content-provider'
import contentfulImageResizer from '../../content-provider/contentful/contentfulImageResizer'
import dateToISO8601 from '../../dateToISO8601'
import Favicons from '../favicons'
import { GoogleTagManagerHeadScripts } from '../google-tag-manager'

interface Props {
  page: Page|null|undefined
  siteSettings: SiteSettings|null|undefined
}

export default (props:Props) => {
  return (
    <Head>
      {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER && (
        <GoogleTagManagerHeadScripts container={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER!} />
      )}

      {process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
        <RecaptchaScript recaptchaSiteKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!} />
      )}

      <Favicons />

      {props.page?.seo.title && (
        <title>{props.page.seo.title}</title>
      )}

      {props.page?.seo.description && (
        <meta name='description' content={props.page.seo.description} />
      )}

      {(props.page?.seo.robots || props.siteSettings?.robots) && (
        <React.Fragment>
          <meta name='robots' content={props.page?.seo.robots || props.siteSettings?.robots} />
        </React.Fragment>
      )}

      {props.siteSettings?.siteName && (
        <meta property='og:site_name' content={props.siteSettings.siteName} />
      )}

      {(props.page?.seo.facebookTitle || props.page?.seo.title) && (
        /* @ts-ignore Yes, by now one of these properties will have content. */
        <meta property='og:title' content={props.page?.seo.facebookTitle || props.page?.seo.title} />
      )}

      {props.page?.seo.facebookType && (
        <meta property='og:type' content={props.page.seo.facebookType} />
      )}

      {props.page?.seo.facebookImage && (
        <meta property='og:image' content={contentfulImageResizer(props.page.seo.facebookImage.url, 1200)} />
      )}

      {props.page?.seo.facebookDescription && (
        <meta property='og:description' content={props.page.seo.facebookDescription} />
      )}

      {props.page?.seo.updatedTimestamp && (
        /* According to https://ogp.me/#data_types, Facebook wants DateTime values in ISO 8601 format */
        <meta property='og:updated_time' content={dateToISO8601(new Date(props.page.seo.updatedTimestamp))} />
      )}

      {props.page?.seo.facebookVideo && (
        <meta property='og:video' content={props.page.seo.facebookVideo} />
      )}

      {props.page?.seo.twitterCard && (
        <meta name='twitter:card' content={props.page.seo.twitterCard} />
      )}

      {props.page?.seo.twitterTitle && (
        <meta name='twitter:title' content={props.page.seo.twitterTitle} />
      )}

      {props.page?.seo.twitterDescription && (
        <meta name='twitter:description' content={props.page.seo.twitterDescription} />
      )}

      {props.page?.seo.twitterImage && (
        <meta name='twitter:image' content={props.page.seo.twitterImage.url} />
      )}
    </Head>
  )
}
