import cx from 'classnames'
import BackgroundStyleComponent, { BackgroundStyle } from '../../components/background-style'
import { Image } from '../../content-provider'
import contentfulImageResizer from '../../content-provider/contentful/contentfulImageResizer'
import Marquee from "react-fast-marquee"
import AnimatedIn from '../../components/viewport-observer'

interface Props {
  tickerImages: Array<Image>
  backgroundStyle: BackgroundStyle
}

export default (props:Props) => {
    return (
      <AnimatedIn className='LogoTickerBlock' classNameObserved='LogoTickerBlock--observed'>
        <BackgroundStyleComponent background={props.backgroundStyle} className={cx('LogoTickerBlock--inner')}>
          <div className='LogoTickerBlock--border'>
            <div className='gradient-border-top'></div>
            <Marquee 
            speed={30}
            gradient={false}
            >
              {props.tickerImages && props.tickerImages.map((tickerImage: Image) =>
                <div key={tickerImage.url} className={cx('ticker-image')}>
                  <img src={contentfulImageResizer(tickerImage.url, 163)} alt="ticker logo" />
                </div>
              )}
            </Marquee>
            <div className='gradient-border-bottom'></div>
          </div>
        </BackgroundStyleComponent>
      </AnimatedIn>
    )
  }