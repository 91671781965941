import cx from 'classnames'
import React from 'react'

interface Props {
  /**
   * Add this className to the DOM all the time.
   */
  className?: string

  /**
   * Add this className to the DOM when the element is in the viewport.
   */
  classNameObserved?: string

  /**
   * Show this content.
   */
  children?: React.ReactNode

  /**
   * If you want to do more than just add a className, add this callback
   */
  onObservedChanged?: (observed:boolean) => any
}

interface State {
  observed:boolean
}

// <performance> We know we are going to have a lot of DOM elements that need to animate when they enter the viewport. There are performance reasons to have a shared IntersectionObserver instance that manages them all, instead of an IntersectionObserver for each DOM element. This section manages all of the DOM elements with a single observer.
type IntersectionObserverCallback = (entry:IntersectionObserverEntry) => any
type IntersectionObserverListener = {
  target: Element
  callback: IntersectionObserverCallback
}

class IntersectionObserverSingleton {
  private static _instance:IntersectionObserverSingleton

  private static getInstance = ():IntersectionObserverSingleton => {
    if (!IntersectionObserverSingleton._instance) {
      IntersectionObserverSingleton._instance = new IntersectionObserverSingleton()
    }

    return IntersectionObserverSingleton._instance
  }

  static observe = (target:Element, callback:IntersectionObserverCallback):void => {
    const instance:IntersectionObserverSingleton = IntersectionObserverSingleton.getInstance()
    instance.observe(target, callback)
  }

  static unobserve = (target:Element, callback:IntersectionObserverCallback):void => {
    const instance:IntersectionObserverSingleton = IntersectionObserverSingleton.getInstance()
    instance.unobserve(target, callback)
  }

  _observer:IntersectionObserver
  _listeners:Array<IntersectionObserverListener>

  private constructor() {
    this._observer = new IntersectionObserver(this.callback, {})
    this._listeners = []
  }

  private observe = (target:Element, callback:IntersectionObserverCallback):void => {
    if (!target) {
      throw new Error('target is required')
    }

    if (!callback) {
      throw new Error('callback is required')
    }

    this._listeners.push({ target, callback })
    this._observer.observe(target)
  }

  private unobserve = (target:Element, callback:IntersectionObserverCallback):void => {
    if (!target) {
      throw new Error('target is required')
    }

    if (!callback) {
      throw new Error('callback is required')
    }

    const match:IntersectionObserverListener|undefined = this._listeners.find( (item:IntersectionObserverListener):boolean => {
      return item.target === target && item.callback === callback
    })
    
    if (!match) {
      console.warn('Tried to unobserve, but no match was found!', this._listeners, target, callback)
      return
    }

    this._observer.unobserve(match.target)
    const index = this._listeners.indexOf(match)
    this._listeners.splice(index, 1)
  }

  private callback = (entries:IntersectionObserverEntry[], observer:IntersectionObserver) => {
    for (const entry of entries) {
      const match:IntersectionObserverListener|undefined = this._listeners.find( (item:IntersectionObserverListener):boolean => {
        return item.target === entry.target
      })

      if (!match) {
        continue
      }

      match.callback(entry)
    }
  }
}
// </performance>

export default class extends React.Component<Props, State> {
  _elementRef:React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  constructor(props:Props) {
    super(props)
    this.state = {
      observed: false
    }
  }

  componentDidMount = ():void => {
    if (!this._elementRef.current) {
      console.warn('No _elementRef set!')
      return
    }

    IntersectionObserverSingleton.observe(this._elementRef.current, this.onObserved)
  }

  componentWillUnmount = ():void => {
    if (!this._elementRef.current) {
      console.warn('No _elementRef set!')
      return
    }

    IntersectionObserverSingleton.unobserve(this._elementRef.current, this.onObserved)
  }

  render = () => {
    return (
      <div 
        ref={this._elementRef} 
        className={cx(this.props.className, this.state.observed && this.props.classNameObserved)}
      >
        {this.props.children}
      </div>
    )
  }

  private onObserved = (entry:IntersectionObserverEntry) => {
    if (this.state.observed === entry.isIntersecting) {
      return
    }

    this.setState({ observed:entry.isIntersecting }, () => {
      if (this.props.onObservedChanged) {
        this.props.onObservedChanged(this.state.observed)
      }
    })
  }
}