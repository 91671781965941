import cx from 'classnames'

interface Props {
  className?: string
  isOpen: boolean
  onClick: () => void
}

export default (props:Props) => {
  return (
    <button 
      className={cx('HamburgerButton', props.className)}
      onClick={props.onClick} 
      aria-expanded={props.isOpen} 
      aria-label='toggle menu'
    >
      <svg
        className={cx('HamburgerButton--svg', props.isOpen && 'HamburgerButton--svgOpen')}
        data-isopen={props.isOpen}
        viewBox='0 0 100 100'
        fill='none'
        stroke='currentColor'
        strokeWidth='5'
        width='50'
      >
        <path
          className='HamburgerButton--topLine'
          d='m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20'
        />
        <path className='HamburgerButton--middleLine' d='m 30,50 h 40' />
        <path
          className='HamburgerButton--bottomLine'
          d='m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20'
        />
      </svg>
    </button>
  )
}