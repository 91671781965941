import cx from 'classnames'
import Anchor from '../../components/anchor'
import BackgroundStyleComponent, { BackgroundStyle } from '../../components/background-style'
import BlockViewportAnimation from '../../components/block-viewport-animation'
import { Image, Link } from '../../content-provider'

interface ThreeUpBlockItem {
  image: Image
  heading: string|null
  subHeading: string|null
  description: string|null
  link: Link|null
}

interface Props {
  backgroundStyle: BackgroundStyle
  items: Array<ThreeUpBlockItem>
}

export default (props:Props) => {
  return (
    <div className='ThreeUpBlock'>
      <BackgroundStyleComponent background={props.backgroundStyle} className='ThreeUpBlock--inner'>
        <BlockViewportAnimation className={cx('container')}>
          <div className={cx('gw')}>
            {props.items && props.items.map( (item:ThreeUpBlockItem) => {
              return (
                <div key={item.heading} className={cx('g one-half desk-one-third ThreeUpBlock--item')}>
                  <img
                    className='ThreeUpBlock--image'
                    src={item.image.url} 
                    alt='left card image' 
                  />
                  {}
                  {item.heading && (
                    <h6 className='ThreeUpBlock--heading typography--subheadAlt'>{item.heading}</h6>
                  )}
                  {item.subHeading && (
                    <p className={cx('ThreeUpBlock--heading', !item.description && 'no-description')}>{item.subHeading}</p>
                  )}
                  {item.description && (
                    <div className='ThreeUpBlock--description' dangerouslySetInnerHTML={{__html: item.description}}></div>
                  )}
                  {item.link && (
                    <Anchor
                      className='ThreeUpBlock--link'
                      link={item.link}
                      forceArrow={true}
                    />
                  )}
                </div>
              )})
            }
          </div>
        </BlockViewportAnimation>
      </BackgroundStyleComponent>
    </div>
  )

}