import assert from 'assert'

/**
 * Tests to see if a URL is external to the current site.
 * 
 * @param {string} siteURL The URL of the current site
 * @param {string|null|undefined} testURL The outgoing URL to test
 * @returns True if the URL is external to the current site, false otherwise
 */
const isExternalURL = (siteURL:string, testURL:string|null|undefined):boolean => {
  if (!siteURL) {
    throw new Error('siteURL is a required argument')
  }

  if (!testURL) {
    return false
  }

  if (testURL.startsWith('mailto:')) {
    return true // We consider mailto: links to be external since they will open up an external application.
  }

  if (!testURL.startsWith('http://') && !testURL.startsWith('https://')) { // If it doesn't include a protocol, then it's definitely internal.
    return false
  }
  
  try {
    const site = new URL(siteURL)
    const actual = new URL(testURL)

    return (site.host !== actual.host)
  }
  catch(err) {
    console.warn('Unable to determine if URL `' + testURL + '` is external!')
    return false
  }
}

export default isExternalURL

export const tests = async () => {
  testExternalURLs()
  testInternalURLs()
}

const testExternalURLs = () => {
  assert.ok(isExternalURL('https://space150.com', 'https://google.com') === true)
  assert.ok(isExternalURL('https://space150.com', 'https://www.google.com') === true)
  assert.ok(isExternalURL('https://space150.com', 'mailto:developers@space150.com') === true) // We consider mailto: links to be external.
}

const testInternalURLs = () => {
  assert.ok(isExternalURL('https://space150.com', '/') === false)
  assert.ok(isExternalURL('https://space150.com', '#') === false)
  assert.ok(isExternalURL('https://space150.com', '/contact') === false)
}